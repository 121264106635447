

.landing-page {
  /* background-color: #f0f0f0; */
  background: linear-gradient(to bottom,black 40%,rgba(238, 195, 5, 0.728));
  text-align: center;
  height:100vh;
  background-attachment: fixed;
  padding: 2rem;
  font-family: Arial, sans-serif;
  
}

header {
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: #007bff; */
  /* background-color: #272529 !important; */
  background-color: transparent !important;
  height:4rem;
  color: #fff;
  /* padding: 1rem; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); /* Subtle box shadow */
}

.logo{
width: 15rem ;
height: 6rem;
}

@media screen and (max-width:900px){
  .logo{
    margin-top: 4rem;
    width: 13rem ;
    height: 5rem;
    }
}

main {
  margin-top: 2rem;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  flex-direction: column; /* Make the button appear below the input */
}

input {
  padding: 0.5rem;
  margin-right: 0; /* Remove right margin */
  border: 1px solid #847b7b;
  border-radius: 4px;
  
}
/* Style for the input field */
.input-container input {
  width: 30%;
  padding: 10px;
  border: 2px solid #007bff; /* Border color of your choice */
  border-radius: 10px; /* Rounded corners */
  background-color: #f7f7f7; /* Background color */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
  font-size: 16px;
  color: #333; /* Text color */
}

@media screen and (max-width:900px){
  .input-container input {
    width:60%;  
  }
}


/* Style for input focus (when the input is selected) */
.input-container input:focus {
  outline: none; /* Remove the default outline */
  border: 2px solid #ff5733; /* Change border color on focus */
}



button {
  /* background-color: #007bff; */
  background-color: #112d4aa0;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s; 
}
button:hover {
  background-color: #0056b3; /* Change the background color on hover */
}

/* Add additional styles as needed */
/* custom.css */



.right-align-list {
text-align: left;
}
/* Styles for the language toggle buttons */
.language-toggle {
position: absolute;
top: 32px;
right: 32px;
}

.language-toggle button {
/* background-color: #421830; */
  /* background-color: #007bff88; */
  background: transparent;
  /* border: 2px solid white; */

color: #fff;
font-weight: bold;
}

/* Style for the active button */
.language-toggle button.active {
/* background-color: #421830; */
  background-color: #007bff;

color: #fff;
font-weight: bold;
}


.bold-text {
color: rgb(121, 117, 125);
text-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
letter-spacing: 0.4px;
font-weight: bold;
}

.landing-page {
background-color: #f0f0f0;
text-align: center;
padding: 2rem;
font-family: Arial, sans-serif;


}

header {
background-color: #007bff;
color: #fff;
padding: 1rem;
}

main {
margin-top: 2rem;
}

.input-container {

display: flex;
justify-content: center;
align-items: center;
margin-bottom: 1rem;
flex-direction: column; /* Make the button appear below the input */
}

input {
padding: 0.5rem;
margin-right: 0; /* Remove right margin */
border: 1px solid #847b7b;
border-radius: 4px;
}
/* Style for the input field */
.input-container input {
padding: 10px;
border: 2px solid #007bff; /* Border color of your choice */
border-radius: 5px; /* Rounded corners */
background-color: #f7f7f7; /* Background color */
box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
font-size: 16px;
color: #333; /* Text color */
}

/* Style for input focus (when the input is selected) */
.input-container input:focus {
outline: none; /* Remove the default outline */
border: 2px solid #ff5733; /* Change border color on focus */
}



button {
background-color: #007bff;
color: #fff;
border: none;
border-radius: 5px;
padding: 10px 20px;
cursor: pointer;
transition: background-color 0.3s; 
}
button:hover {
background-color: #0056b3; /* Change the background color on hover */
}



/* Style for the disclaimer section */
.disclaimer-section {
color: white; 
direction: ltr;
/* background-color: #007BFF; */
/* background-color: #054829; */
background-color: rgba(0, 0, 0, 0.8);
/* background-color: transparent; */
padding: 20px; /* Increased padding for spacing */
margin-top: 50px; /* Margin for separation from other content */
font-family: Arial, sans-serif;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); /* Subtle box shadow */
/*text-align: left;  Align text to the left for better readability */
border-radius: 10px; /* Rounded corners */
}



.disclaimer-section.right {
direction: rtl;
text-align: right;
color: white; 

}

/* Style for the disclaimer section title */
.disclaimer-section h2 {
font-size: 24px; 
color: white; 
}

/* Style for the list items in the disclaimer section */
.right-align-list {
list-style: disc;
padding-left: 20px;
margin-top: 15px; 
color: #f7f7f7; 
text-align: right;
}

/* Style for the individual list items */
.right-align-list li {
font-size: 16px; 
margin-bottom: 10px; 
color: white; 
font-style: none;
}


.disclaimer-section ul li {
font-size: 14px; 
}



/* Add this CSS to your custom.css file */
.notification p.error-text {
color: red;
font-weight: bold;
}

.loader {
display: flex;
align-items: center;
justify-content: center; 
min-height: 100px; 
}

.points {
list-style-type: disc; /* Add this property to show bullets for English list items */
padding-left: 20px;
margin-top: 15px;
color: #f7f7f7;
text-align: left; /* Align text to the left for better readability */
}

.points li {
font-size: 16px;
margin-bottom: 10px;
color: white;
font-style: none;
}

.selector{
  width: 220px;
  margin: 10px 0;
  height: 40px;
  border-radius: 2px;
}

.message{
  color: #fff;
  font-size: 18px;
}

















